import React from "react";
import io from 'socket.io-client';
const { Component } = React;
import { getURLParams } from '../Common'

import UnitChargeFree from "./UnitChargeFree";
import UnitChargeAccount from "./UnitChargeAccount";
import UnitChargeCreditCard from "./UnitChargeCreditCard";
import UnitChargeCharging from "./UnitChargeCharging";


export default class UnitCharge extends Component {
    params = {};
    state = {

    };

    componentDidMount() {
        this.init();
        this.getUnitData();
    }

    init() {
        let root = document.getElementById('unit-charge-stop');
        this.params.csrf_token = root.getAttribute('data-csrf-token');
        this.urlParams = getURLParams();
        this.unitUid = root.getAttribute('data-unit-uid');
        this.transactionUid = root.getAttribute('data-transaction-uid');
        this.unitDataUrl = root.getAttribute('data-opendata-url') + '/api/unit/' + this.unitUid;
        this.socketUrl = root.getAttribute('data-socket-url')
    }

    initSocket(unit_uid, tech_backend) {
        this.socket = io(this.socketUrl + '/unit/charge');
        this.socket.on('connect', () => {
            this.socket.emit('resume', {
                unit: this.transactionUid,
                target: tech_backend,
                uid: this.transactionUid
            });
        });
        this.socket.on('resume-data', (data) => {
            let token_to_auth = {
                'iban': 'account',
                'creditcard': 'creditcard',
                'user': 'free'
            };
            this.setState({
                requestUid: data.requestUid,
                accountDescr: data.accountDescr,
                appStatus: 'charging',
                authData: data.auth,
                authType: token_to_auth[data.tokenType],
                resumed: true,
                initialized: true
            })
        });

        this.socket.on('unit-update', (data) => {
            if (data.type === 'StopTransaction') {
                this.updateStopTransaction(data.data);
            }
        });
    }


    getUnitData() {
        $.get(this.unitDataUrl)
            .then(data => {
                if (data.status) {
                    this.setState({
                        appStatus: 'invalid-unit'
                    });
                    return;
                }
                this.setState({
                    unitGroup: data.data['unit-group'],
                    unit: data.data
                });
                this.initSocket(data.data.uid, data.data.tech_backend);
            });
    }

    chargeStop(evt) {
        evt.preventDefault();
        this.setState({
            appStatus: 'stopping'
        });
        let params = {
            csrf_token: this.params.csrf_token,
            uid: this.transactionUid,
            target: this.state.unit.tech_backend
        };
        $.post('/api/unit/charge/stop', params)
            .then(data => {
                localStorage.removeItem('unit-charge');
                if (this.state.unit.tech_backend === 'tcc' && !data.status) {
                    this.setState({
                        appStatus: 'completed'
                    });
                }
            });
    }

    render() {
        if (!this.state.initialized)
            return <div>...</div>;
        return(
            <div className="row row-form">
                {this.renderChargeIncomplete()}
                {this.renderChargeStopping()}
                {this.renderChargeComplete()}
            </div>
        );
    }


    renderChargeIncomplete () {
        if (this.state.appStatus !== 'charging')
            return;
        return (
            <div className="col-md-12">
                <h3>Ladevorgang läuft</h3>
                <p>
                    <button className="btn btn-block btn-primary" onClick={this.chargeStop.bind(this)}>
                        Ladevorgang beenden
                    </button>
                </p>
            </div>
        )
    }

    renderChargeStopping () {
        if (this.state.appStatus !== 'stopping')
            return;
        return (
            <div className="col-md-12">
                <p><i className="fa fa-spinner fa-spin fa-fw"></i> Ladevorgang wird beendet ...</p>
            </div>
        )
    }


    renderChargeComplete () {
        if (this.state.appStatus !== 'completed')
            return;
        return(
            <div className="col-md-12">
                <h3>Ladevorgang abgeschlossen</h3>
            </div>
        )
    }
}