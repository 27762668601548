import React from "react";
import {Decimal} from 'decimal.js';
import moment from 'moment';
import { Scatter } from 'react-chartjs-2';
import {formatDuration, formatEnergy, formatPower, formatPrice} from "../Format";

const { Component } = React;

export default class UnitChargeCharging extends Component {
    state = {
        initialized: false,
        forecast: false,
        chartjsData: null
    };

    componentDidMount() {
        this.chartjsOptions = {
            animation: { duration: 0 },
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                yAxes: [
                    {
                        id: 'energy',
                        position: 'left',
                        scaleLabel: {
                            fontSize: 14,
                            labelString: 'Energie [kWh]',
                            display: true
                        },
                        ticks: {
                            fontSize: 14
                        }
                    },
                    {
                        id: 'power',
                        position: 'right',
                        scaleLabel: {
                            fontSize: 14,
                            labelString: 'Leistung [kW]',
                            display: true
                        },
                        ticks: {
                            fontSize: 14
                        }
                    }
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            fontSize: 14,
                            labelString: 'Zeit [Minuten]',
                            display: true
                        },
                        ticks: {
                            fontSize: 14
                        }
                    }
                ]
            },
            tooltips: {
                titleFontSize: 14,
                bodyFontSize: 14,
                callbacks: {
                    label: (tooltipItem, data) => this.renderTooltipLabel(tooltipItem, data)
                }
            }
        };
        this.chargeHeartbeat();
    }

    componentWillUnmount() {
        if (this.heartbeat) {
            clearTimeout(this.heartbeat)
        }
    }

    chargeHeartbeat() {
        this.heartbeat = setTimeout(this.chargeHeartbeat.bind(this), 1000);
        if (!this.props.authData || !Object.keys(this.props.meterHistory).length)
            return;

        let data = {
            amountTime: parseInt(moment.duration(moment.utc().diff(this.props.unitChargeStart)).as('seconds'))
        };
        let times = Object.keys(this.props.meterHistory);
        if (times.length > 1) {
            times.sort((a, b) => parseInt(a) - parseInt(b));
            data.forecast = true;
            data.amountTimeMeasured = times[times.length - 1];
            data.amountEnergyMeasured = this.props.meterHistory[times[times.length - 1]];

            let duration_energy_factor = Decimal(data.amountTime).div(data.amountTimeMeasured);
            data.amountEnergyForecast = Decimal(data.amountEnergyMeasured).mul(duration_energy_factor);
            data.feeBaseForecast = Decimal(this.props.authData.feeBase);
            data.feeEnergyForecast = Decimal(this.props.authData.feeEnergy).div(1000).mul(data.amountEnergyForecast);
            data.feeTimeForecast = Decimal(this.props.authData.feeTime).div(3600).mul(data.amountTime);
            data.energyByHour = data.amountEnergyForecast.div(Decimal(data.amountTime).div(3600));
            data.feeTotalForecast = data.feeBaseForecast.plus(data.feeEnergyForecast).plus(data.feeTimeForecast);


            let measurements = [];
            let powers = [];
            for (let i = 0; i < times.length; i++) {
                measurements.push({
                    x: times[i] / 60,
                    y: this.props.meterHistory[String(times[i])] / 1000
                });
                if (i === 0)
                    continue;
                if (i === 1) {
                    powers.push({
                        x: 0,
                        y: (this.props.meterHistory[String(times[i])] - this.props.meterHistory[String(times[i - 1])]) / (parseInt(times[i]) - parseInt(times[i - 1])) * 3.6
                    });
                }
                powers.push({
                    x: (parseInt(times[i - 1]) + (parseInt(times[i]) - parseInt(times[i - 1])) / 2) / 60,
                    y: (this.props.meterHistory[String(times[i])] - this.props.meterHistory[String(times[i - 1])]) / (parseInt(times[i]) - parseInt(times[i - 1])) * 3.6
                });
                if (i === times.length - 1) {
                    powers.push({
                        x: parseInt(times[i]) / 60,
                        y: (this.props.meterHistory[String(times[i])] - this.props.meterHistory[String(times[i - 1])]) / (parseInt(times[i]) - parseInt(times[i - 1])) * 3.6
                    });
                }
            }
            data.chartjsData = {
                labels: ['Scatter'],
                datasets: [
                    {
                        label: 'Energie',
                        borderColor: 'rgba(215, 236, 3, 1)',
                        backgroundColor: 'rgba(215, 236, 3, 1)',
                        showLine: true,
                        fill: false,
                        yAxisID: 'energy',
                        data: measurements
                    },
                    {
                        label: 'Leistung',
                        borderColor: 'rgba(215, 10, 3, 1)',
                        backgroundColor: 'rgba(215, 10, 3, 1)',
                        showLine: true,
                        fill: false,
                        yAxisID: 'power',
                        data: powers
                    }
                ]
            };
        }
        this.setState(data);
    }

    triggerUnitUpdate() {
        let params = {
            csrf_token: this.params.csrf_token,
            unit: this.state.unit.uid
        };
        $.post('/api/unit/charge/trigger-unit-status', params);
    }


    render() {
        return(
            <div className="row row-form">
                <div className="col-md-12">
                    <h2>Prognose</h2>
                    <p>Hinweis 
                        Abgerechnet werden am Ende die geeichten Messwerte. Dynamsche oder kartenbezogene Rabatte werden
                        auch erst bei der finalen Abrechnung berücksichtigt.
                    </p>
                    {this.renderLiveView()}
                </div>
            </div>
        )
    }

    renderLiveView() {
        let result = [];
        result.push(
            <p key="duration">
                <i className="fa fa-clock-o" aria-hidden="true"></i>{' '}
                {formatDuration(this.state.amountTime)} Stunden
            </p>
        );
        if (!this.state.forecast) {
            result.push(
                <p key="energy-waiting">
                    <i className="fa fa-spinner fa-spin fa-fw"></i> Warte auf die erste Energie-Messung ...
                </p>
            );
            return result;
        }
        result.push(
            <p key="energy-forecast">
                <i className="fa fa-bolt" aria-hidden="true"></i>{' '}
                {formatEnergy(this.state.amountEnergyForecast, 3)} bei{' '}
                {formatPower(this.state.energyByHour)} / Stunde
            </p>
        );
        result.push(
            <p key="amount-forecast">
                <i className="fa fa-euro" aria-hidden="true"></i>{' '}
                Kostenprognose: {formatPrice(this.state.feeTotalForecast)},
                davon Basis {formatPrice(this.state.feeBaseForecast)},
                Energie: {formatPrice(this.state.feeEnergyForecast)} und
                Zeit: {formatPrice(this.state.feeTimeForecast)}
            </p>
        );
        if (this.state.chartjsData) {
            result.push(
                <div key="energy-graph">
                    <Scatter
                        data={this.state.chartjsData}
                        height={500}
                        options={this.chartjsOptions}
                        redraw
                    />
                </div>
            );
        }
        return result;
    }

    renderTooltipLabel(tooltipItem, data) {
        if (tooltipItem.datasetIndex === 0) {
            return 'Messung in Minute '
                + this.renderMinutes(tooltipItem.xLabel)
                + ': ' + this.renderEnergy(tooltipItem.yLabel)
                + ' kWh';
        }
        if (tooltipItem.datasetIndex === 1) {
            return 'Ladeleistung in Minute '
                + this.renderMinutes(tooltipItem.xLabel)
                + ': ' + this.renderEnergy(tooltipItem.yLabel)
                + ' kW';
        }
    }


    renderMinutes(value) {
        return Math.floor(value) + ':' + this.prependZero(Math.floor(value % 1 * 60));
    }

    prependZero(value) {
        if (value < 10)
            return '0' + String(value);
        return String(value);
    }

    renderEnergy(value) {
        return String(Math.round(value * 1000) / 1000).replace('.', ',');
    }

}